//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    @include translate(0, -25%);
    @include transition-transform(0.3s ease-out);
  }
  &.in .modal-dialog { @include translate(0, 0) }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: $modal-content-bg;
  border: 1px solid $modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid $modal-content-border-color;
  border-radius: $border-radius-large;
  @include box-shadow(0 3px 9px rgba(0,0,0,.5));
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-background;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade { @include opacity(0); }
  &.in { @include opacity($modal-backdrop-opacity); }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: $modal-title-padding;
  border-bottom: 2px solid $modal-header-border-color;
  @include clearfix;
}
// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  margin: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  padding: $modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid $modal-footer-border-color;
  @include clearfix; // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Modal in types
.modal-warning {
  .modal-header { background: darken($panel-warning-heading-bg, 30%); }
}
.modal-info {
  .modal-header { color: #fff; background: darken($panel-info-heading-bg, 30%); }
}
.modal-primary {
  .modal-header { color: #fff; background: $panel-primary-heading-bg; }
}
.modal-danger {
  .modal-header { color: #fff; background: darken($panel-danger-heading-bg, 30%); }
}

// Scale up the modal
@media (min-width: $screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: $modal-md;
    margin: 30px auto;
  }
  .modal-content {
    @include box-shadow(0 5px 15px rgba(0,0,0,.5));
  }

  // Modal sizes
  .modal-sm { width: $modal-sm; }
  .modal-mdsm { width: $modal-mdsm; }
}

@media (min-width: $screen-md-min) {
  .modal-lg { width: $modal-lg; }
}

// view product
.modal-gray{
  background-color: #e6e6e6 !important;
}


/* Notify  product detail */
.modal-product-body {
  position: absolute;
  height: 81px;
}

.modal-padding .modal-product-body {
  padding-left: 116px;
  padding-top: 299px;
  font-size: 18px;
}

.modal-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5cm;
  width: 118px;
  height: 127px;
  left: 0px;
}

.modal-word-close {
  color: #FF0000;
  text-align: center;
}

.modal-ref-code {
  color: #404040;
  font-size: 16px;
  font-family: 'Kanit', sans-serif;
}

.modal-prod-name {
  color: #404040;
  font-size: 16px;
  font-family: 'Kanit', sans-serif;
}

.modal-quantity {
  color: #404040;
  font-size: 16px;
  font-family: 'Kanit', sans-serif;
}

.modal-footer-notify {
  padding: 15px;
  text-align: right;
  border-top: none;
}

.modal-text-noti {
  font-size: 16px;
  font-family: 'Kanit', sans-serif;
}

.modal-zero-content-noti {
  font-size: 16px;
  font-family: 'Kanit', sans-serif;
}

.btn_close {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 15px;
  gap: 10px;

  position: absolute;
  width: 80px;
  height: 30px;
  left: 168px;
  top: 430px;

  border: 1px solid #DC3545;
  border-radius: 5px;
}

.btn_confirm {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 15px;
  gap: 10px;

  position: absolute;
  width: 80px;
  height: 39px;
  left: 258px;
  top: 430px;

  border: 1px solid #67D786;
  background: #67D786;
  border-radius: 5px;
}

.text_close {
  padding-left: 15px;
  width: 50px;
  height: 27px;

  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;

  color: #DC3545;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.text_confirm {
  width: 50px;
  height: 27px;

  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;

  /* color/white */
  color: #FFFFFF;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-content-noti {
  height: 497px;
}

.modal-zero-content-noti {
  height: 597px;
}

.modal-text-content-noti {
  padding-left: 116px;
  width: 1006;
	font-family: 'Kanit', sans-serif;
}

.modal-text-noti-danger {
  text-align: center; 
  font-size: 40px; color: #FF0000; 
  font-family: 'Kanit', sans-serif; 
  margin-top: 13px;
}

.btn-outline-danger-custom {
  border: 1px solid #dc3545;
  color: #dc3545;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  background-color: #FFF;
  border-radius: 4px;
}

.btn-outline-danger-custom:hover {
  color: #FFF;
  background-color: #dc3545;
  box-shadow: 0 0 0 0.25rem #ff000050;
  border-radius: 4px;
}

.btn-confirm {
  background-color: #67D786;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-zero-comfirm {
  background-color: #CDCDCD;
  padding-left: 20px;
  padding-right: 20px;
  border-color: #CDCDCD;
}

.btn-content-noti {
  display: flex; 
  justify-content: center;
  text-align: center;
  gap: 20px;
  width: 600px;
  margin-top: 20px; 
  font-size: 18px;
}

.warning-deactive-noti {
  text-align: center;color: red;font-size: 16px;font-family: 'Kanit', sans-serif;border-bottom: 1px solid #404040;border-bottom-width: thin;margin-left:  51px;margin-right: 51px;padding-bottom: 18px;
}

.warning-delete-noti-first {
  text-align: center;
  color: red;
  font-size: 16px;
  font-family: 'Kanit', sans-serif;
  margin-left: 51px;
  margin-right: 51px;
  padding-bottom: 18px;
}